import gql from 'graphql-tag'

export const GET_LOGIN_HISTORY_PAGINATION_QUERY = gql`
  query GetLoginHistoryPagination (
    $orderBy: [OrderByInput!],
    $skip: Int!,
    $take: Int!,
    $search: String
    $excludeSearch: Boolean
    $loginDate: DateString
    $loginTime: TimeString
    $logoutDate: DateString
    $logoutTime: TimeString
  ) {
    getLoginHistories(
      data: {
        orderBy: $orderBy
        pagination: {
          skip: $skip
          take: $take
        }
        search: $search,
        excludeSearch: $excludeSearch,
        loginDate: $loginDate,
        loginTime: $loginTime,
        logoutDate: $logoutDate,
        logoutTime: $logoutTime
      }
    ){
      hasMore
      total
      items {
        id
        fmuId
        fmuFullName
        ipAddress
        loginAt
        logoutAt
      }
    }
  }
`
