import { handleGqlResponseBody } from "@/utils/apollo.util"
import { GET_PROPERTIES_PAGINATION_QUERY } from "@/graphql/queries/property/get-properties-pagination.query"
import { GET_PROPERTY_QUERY } from '@/graphql/queries/property/get-property.query'
import { CREATE_PROPERTY_MUTATION } from '@/graphql/mutations/property-cud/create.mutation'
import { UPDATE_PROPERTY_MUTATION } from '@/graphql/mutations/property-cud/update.mutation'
import { DELETE_PROPERTY_MUTATION } from '@/graphql/mutations/property-cud/delete.mutation'
import {
  GET_PROPERTY_FLOORS_WITH_ROOMS_AND_USERS_QUERY,
  GET_PROPERTY_FLOORS_WITH_ROOMS_QUERY
} from '@/graphql/queries/property/get-property-floors-with-rooms.query'
import { BATCH_PROPERTY_FLOOR_CUD_MUTATION } from '@/graphql/mutations/property-cud/batch-floors.mutation'

const property = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getPropertyQuery ({ commit }, { id }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTY_QUERY,
          variables: { id },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async getPropertiesQuery ({ commit }, {
      skip, take, orderBy, name, ownerIds
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTIES_PAGINATION_QUERY,
          variables: {
            skip, take, orderBy, name, ownerIds
          },
          fetchPolicy: "no-cache"
        })
      })
    },
    async createProperty ({ commit }, {
      address, floorsNumber, indoorTablet, name, smartIntercom, streetAddress, zipCode
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: CREATE_PROPERTY_MUTATION,
          variables: {
            address, floorsNumber, indoorTablet, name, smartIntercom, streetAddress, zipCode
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async updateProperty ({ commit }, {
      id, address, floorsNumber, indoorTablet, name, smartIntercom, streetAddress, zipCode
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: UPDATE_PROPERTY_MUTATION,
          variables: {
            id, address, floorsNumber, indoorTablet, name, smartIntercom, streetAddress, zipCode
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async deleteProperty ({ commit }, { id }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: DELETE_PROPERTY_MUTATION,
          variables: { id },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async getPropertyFloorsWithRooms ({ commit }, { propertyId }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTY_FLOORS_WITH_ROOMS_QUERY,
          variables: { propertyId },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async getPropertyFloorsWithRoomsAndUsers ({ commit }, { propertyId }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTY_FLOORS_WITH_ROOMS_AND_USERS_QUERY,
          variables: { propertyId },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async batchPropertyFloorCud ({ commit }, propertyFloors) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: BATCH_PROPERTY_FLOOR_CUD_MUTATION,
          variables: { propertyFloors },
          fetchPolicy: 'no-cache'
        })
      })
    }
  }
}

export default property
