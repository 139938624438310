import gql from 'graphql-tag'

export const CREATE_PROPERTY_ROOM_REPRESENTATIVE_USER_MUTATION = gql`
  mutation CreatePropertyRoomRepresentativeUserMutation (
    $propertyRoomId: Int!
    $email: String!
    $firstName: String!
    $lastName: String!
    $firstNameKana: String
    $lastNameKana: String
    $isActive: Boolean!
    $telNumber: String!
  ) {
    createPropertyRoomRepresentativeUser(
      data: {
        propertyRoomId: $propertyRoomId
        email: $email
        firstName: $firstName
        lastName: $lastName
        firstNameKana: $firstNameKana
        lastNameKana: $lastNameKana
        isActive: $isActive
        telNumber: $telNumber
      }
    ){
      id
    }
  }
`
