import Vue from 'vue'
import VueRouter from 'vue-router'
import PageNotFound from '@/views/PageNotFound'
import MainComponent from '@/components/MainComponent'
import AuthComponent from "@/components/AuthComponent"
import { getFromLocalStorage } from "@/utils/local-storage.util"
import { USER_ROLE } from "@/enums/user-role.enum"
import AccessDenied from "@/views/AccessDenied"

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: MainComponent,
    meta: {
      auth: true
    },
    children: [
      { path: '/', redirect: '/facilities' },
      {
        path: '/facilities',
        component: () => import('../views/main/HomeView.vue'),
        children: [
          {
            path: '',
            name: 'facilities',
            component: () => import('../components/facilities/Facilities.vue')
          },
          {
            path: ':id/details',
            name: 'facilityDetails',
            component: () => import('../components/facilities/Details.vue')
          },
          {
            path: ':id/intercom-devices',
            name: 'facilityIntercoms',
            component: () => import('../components/facilities/IntercomList.vue')
          },
          {
            path: ':id/ev-stations',
            name: 'facilityEvStations',
            component: () => import('../components/facilities/EvStationList')
          }

        ]
      },
      {
        path: '/users',
        meta: {
          permissions: [USER_ROLE.ADMIN]
        },
        component: () => import('../views/main/UsersView.vue'),
        children: [
          {
            path: '',
            name: 'users',
            component: () => import('../components/users/Users.vue')
          }
        ]
      },
      {
        path: 'login-history',
        meta: {
          permissions: [USER_ROLE.ADMIN]
        },
        name: 'loginHistory',
        component: () => import('../components/users/LoginHistory.vue')
      }
    ]
  },
  {
    path: '/',
    component: AuthComponent,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import('../views/LoginView.vue')
      },
      {
        path: '/restore-user-password',
        name: 'restore-user-password',
        component: () => import('../views/UserRestorePasswordView.vue')
      },
      {
        path: '/confirm-restore-user-password',
        name: 'confirm-restore-user-password',
        component: () => import('../views/UserConfirmRestorePasswordView.vue')
      },
      {
        path: '/restore-family-member-password',
        name: 'restore-family-member-password',
        component: () => import('../views/FamilyMemberRestorePasswordView.vue')
      },
      {
        path: '/confirm-restore-family-member-password',
        name: 'confirm-restore-family-member-password',
        component: () => import('../views/FamilyMemberConfirmRestorePasswordView.vue')
      }
    ]
  },
  {
    path: '/access-denied',
    component: AccessDenied
  },
  {
    path: '*',
    component: PageNotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// check redirect role,permission
router.beforeEach(async (to, from, next) => {
  const isLoggedIn = getFromLocalStorage('accessToken')
  if (to.matched.some(m => m.meta.auth) && !isLoggedIn) {
    return next({
      path: '/login'
    })
  }

  const tmpUser = getFromLocalStorage('user')
  if (isLoggedIn && tmpUser) {
    const user = JSON.parse(tmpUser)
    if (user.role !== USER_ROLE.ADMIN && !to.matched.filter(m => m.meta.permissions && m.meta.permissions.length).every(m => m.meta.permissions.includes(user.role))) {
      return next({
        path: '/access-denied',
        query: {
          fromPage: from.path
        }
      })
    }
  }

  // end check router prior with permission, role
  return next()
})

export default router
