import gql from 'graphql-tag'

export const CREATE_ADMIN_USER_MUTATION = gql`
  mutation CreateAdminUser (
    $firstName: String!,
    $firstNameKana: String,
    $lastName: String!,
    $lastNameKana: String,
    $email: String!,
    $telNumber: String!,
    $isActive: Boolean!
  ){
    createAdminUser(
      data: {
        firstName: $firstName,
        lastName: $lastName,
        firstNameKana: $firstNameKana,
        lastNameKana: $lastNameKana,
        email: $email,
        isActive: $isActive,
        telNumber: $telNumber
      }
    ) {
      id
      email
      firstName
      firstNameKana
      lastName
      lastNameKana
      telNumber
      isActive
    }
  }
`

export const CREATE_PROPERTY_OWNER_USER_MUTATION = gql`
  mutation CreatePropertyOwnerUser (
    $firstName: String!,
    $firstNameKana: String,
    $lastName: String!,
    $lastNameKana: String,
    $email: String!,
    $telNumber: String!,
    $isActive: Boolean!
  ){
    createPropertyOwnerUser(
      data: {
        firstName: $firstName,
        lastName: $lastName,
        firstNameKana: $firstNameKana,
        lastNameKana: $lastNameKana,
        email: $email,
        isActive: $isActive,
        telNumber: $telNumber
      }
    ) {
      id
      email
      firstName
      firstNameKana
      lastName
      lastNameKana
      telNumber
      isActive
    }
  }
`
