import gql from 'graphql-tag'

export const BATCH_PROPERTY_FLOOR_CUD_MUTATION = gql`
  mutation BatchPropertyFloorCudMutation (
    $propertyFloors: [BatchPropertyFloorInput!]!
  ){
    batchPropertyFloorCudOperation(
      data: {
        propertyFloors: $propertyFloors
      }
    )
  }
`
