<template>
  <v-dialog
    content-class="bg-white"
    width="400"
    v-model="isVisible"
    persistent
  >
    <v-card class="px-8 py-6">
      <h3 class="text-center">{{ title }}</h3>
      <p v-if="description" class="my-6 mx-2">{{ description }}</p>

      <div class="modal-buttons-wrapper pa-0 mt-6">
        <v-btn
          color="tertiary"
          @click="close"
          class="text-color-white mx-2"
          type="button"
          elevation="0"
        >
          キャンセル
        </v-btn>
        <v-btn
          color="primary"
          class="mx-2"
          type="submit"
          elevation="0"
          @click="confirm"
          :loading="isLoading"
        >
          次へ
        </v-btn>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  name: 'OperationExecutionConfirmationModalComponent',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    data: {
      type: Object,
      default: null
    }
  },
  computed: {
    isVisible: {
      get () {
        return this.visible
      },
      set (value) {
        if (!value) {
          this.close()
        }
      }
    },
    isLoading () {
      return this.loading
    }
  },
  methods: {
    close () {
      this.$emit('close')
    },
    confirm () {
      this.$emit('confirm', this.data)
    }
  }
}
</script>
