import { getDateTime, indexOfRegex } from "@/utils/string"

export function toThousands (num) {
  if (num === null) return ''
  num = Number(num)
  if (isNaN(num)) return ''
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
export function currency (value, symbol = '¥') {
  let final = Math.round(value * 100) / 100
  final = toThousands(final)
  return `${symbol}${final}`
}
export function postalCode (code) {
  if (code) {
    return `〒${code.substring(0, 3)}-${code.substring(3)}`
  }
  return ''
}

export function ellipsis (value, length = 20) {
  value = `${value || ''}`
  if (value.length > length) {
    return value.slice(0, length) + '...'
  } else {
    return value
  }
}

export function dateTime (v, options = { delimiter: '/' }) {
  return getDateTime(v, options)
}

function findNameSplitIndex (name) {
  if (!name) return null
  // eslint-disable-next-line no-irregular-whitespace
  const ind = indexOfRegex(name, /[ 　]/)
  return ind
}

export function extractFirstName (name) {
  if (!name) return name

  const ind = findNameSplitIndex(name)
  return name.substring(ind + 1)
}

export function extractLastName (name) {
  if (!name) return name

  const ind = findNameSplitIndex(name)
  return name.substring(0, ind)
}
