export function getFromLocalStorage (key) {
  return localStorage.getItem(key)
}

export function setToLocalStorage (key, value) {
  localStorage.setItem(key, value)
  return value
}

export function deleteFromLocalStorage (key) {
  localStorage.removeItem(key)
}
