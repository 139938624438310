<template>
  <div>
    アクセスが拒否されました、十分な権限がありません
  </div>
</template>

<script>

export default {
  name: 'AccessDenied'
}
</script>

<style scoped>

</style>
