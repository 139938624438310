import gql from 'graphql-tag'

export const GET_CURRENT_USER_QUERY = gql`
  query GetCurrentUserQuery {
    getCurrentUser {
      id
      role
      email
      propertyRoomId
      firstName
      lastName
    }
  }
`
