<template>
  <v-dialog
    content-class="bg-white"
    :width="parsedWidth"
    :value="value"
    @input="emitInput"
    :persistent="persistent"
  >
    <slot v-bind="{close}"/>
  </v-dialog>
</template>

<script>
export default {
  props: {
    persistent: {
      type: Boolean,
      default: true
    },
    value: Boolean,
    width: {
      type: String,
      default: 'medium'
    }
  },
  computed: {
    parsedWidth () {
      if (this.width === 'medium') {
        return '600'
      } else {
        return this.width
      }
    }
  },
  methods: {
    emitInput (v) {
      this.$emit('input', v)
    },
    close () {
      this.emitInput(false)
    }
  }
}
</script>

<style>

</style>
