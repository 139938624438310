<template>
  <v-app class="custom-bg auth-bg">
    <v-main>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md6 lg4>
            <v-card class="elevation-12" :loading="isLoading" :disabled="isLoading">
              <div class="py-6 px-2">
                <v-card-title class="logo-context ">
                  <logo-component color="#E98700" :width="48" :height="34" />
                  <div class="logo-title">{{ $appName }}</div>
                </v-card-title>

                <slot />
              </div>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LogoComponent from "@/components/LogoComponent"

export default {
  name: 'AuthLayout',
  components: { LogoComponent },
  computed: {
    isLoading () {
      return this.$store.getters.isLoading
    }
  }
}
</script>

<style scoped>
  .auth-bg {
    background: #DBAD31!important;
  }

  .logo-context {
    justify-content: center;
  }
  .logo-title {
    padding-left: 6px;
    color: #E98700;
  }
</style>
