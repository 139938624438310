import gql from 'graphql-tag'

export const DELETE_INTERCOM_MUTATION = gql`
  mutation DeleteIntercom (
    $id: Int!
    $propertyId: Int!
  ){
    deleteIntercom(
      id: $id,
      propertyId: $propertyId
    )
  }
`
