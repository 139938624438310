<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 44 32.017" v-if="!$isDaikoku">
    <g id="Group_10210" data-name="Group 10210" transform="translate(-574.747 -630.053)">
      <g id="Group_10209" data-name="Group 10209" transform="translate(574.747 630.053)">
        <path id="Path_4407" data-name="Path 4407" d="M598.987,632.979c2.192-1.447,4.843.586,4.508,3.189-.052.4-.2,1.161-.863,1.131-.33-.662.241-1.25.1-2.16a2.208,2.208,0,0,0-2.781-1.749c-1.017.287-1.26,1.239-1.822,2.057,2.819,2.547,4.736,6.316,5.659,11.11.29,1.508.255,4.539,1.726,4.835a1.929,1.929,0,0,0,2.3-1.852c.091-1.466-1.489-2.83-2.4-3.6-.281-.238-.906-.458-.959-.926-.12-1.054,1.9-2.914,2.973-3.086a4.106,4.106,0,0,1,4.7,5.041c2.474-1.63,6.516-1.205,6.618,2.263a6.573,6.573,0,0,1-1.151,3.806c-1.6-.377-2.5-1.574-4.7-1.337-3.444.371-5.008,4.084-7.385,5.864-4.115,3.081-11.122,5.843-18.128,3.806-5.762-1.675-9.762-6.026-11.605-12.242a17.939,17.939,0,0,1-.959-7.2,8.467,8.467,0,0,1,1.822-4.115c3.976-4.862,14.742-7.218,20.813-2.674,1.587-1.606.477-4.9-1.918-4.32-.943.226-1.055,1.179-2.014,1.337C593.539,628.98,599.353,629.519,598.987,632.979Zm-6.618,9.875a1.684,1.684,0,0,0,2.11-1.543c-1.819,2.578-3.675-2.466-.863-2.572C591.339,638.393,590.769,641.972,592.369,642.855Zm-15.346.72c-.522-.468-1.036-.946-1.535-1.44-.005-.108-.123-.081-.1.1.036,10.523,5.01,16.3,12.373,18.619C581.94,657.5,577.845,652.294,577.023,643.575Zm4.8,1.132a19.578,19.578,0,0,0,7.961,14.3c1.356.971,3.285,2.319,4.988,2.366,1.687.046,3.6-.818,5.179-1.44a16.047,16.047,0,0,0,4.412-2.366,31,31,0,0,1-5.371.1c.323-.943,1.481-2.835.671-3.909-.341,1.882-.866,5.167-2.685,4.835-.793-.144-1.13-1.47-1.343-2.572-.225-1.167,0-2.653-.576-3.395a15.023,15.023,0,0,0,.383,4.629c-5.651-1.153-10.891-7.557-8.44-14.3A9.7,9.7,0,0,1,581.819,644.706Zm3.645,13.99c2.165,1.682,4.115,3.115,7.577,2.88-6.285-3.066-10.725-8.111-11.7-16.871-1.673.251-2.838-.59-3.836-.72A21.139,21.139,0,0,0,585.463,658.7Z" transform="translate(-574.747 -630.053)" :fill="color" fill-rule="evenodd"/>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LogoComponent',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 44
    },
    height: {
      type: Number,
      default: 32
    }
  }
}
</script>
