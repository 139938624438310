export function toZPad (value, zeros = 2) {
  return `${value}`.padStart(zeros, '0')
}

export function cutValue (value, to) {
  return `${value.substring(0, to)}...`
}

export function getDateTime (datetime, options = {
  withSecond: false,
  delimiter: null
}) {
  const date = new Date(datetime)

  return `${delimitedDateString(date, options?.delimiter ?? '-')} ${date.toTimeString().substring(0, options?.withSecond ? 8 : 5)}`
}

export function delimitedDateString (
  date,
  delimiter
) {
  return `${date.getFullYear()}${delimiter}${toZPad(
    date.getMonth() + 1
  )}${delimiter}${toZPad(date.getDate())}`
}

export function parseDatesRange (startDate, endDate) {
  let delta = (endDate.getTime() - startDate.getTime()) / 1000

  const days = Math.floor(delta / 86400)
  delta -= days * 86400

  const hours = Math.floor(delta / 3600) % 24
  delta -= hours * 3600

  const minutes = Math.floor(delta / 60) % 60
  delta -= minutes * 60

  const seconds = Math.floor(delta % 60)

  return `${days ? `${days}日` : ''}${hours ? `${hours}時間` : ''}${minutes ? `${minutes}分` : ''}${seconds ? `${seconds}秒` : ''}`
}

export function indexOfRegex (str, regex) {
  const match = str.match(regex)
  return match ? str.indexOf(match[0]) : -1
}
