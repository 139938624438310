import gql from 'graphql-tag'

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser (
    $id: Int!,
    $firstName: String,
    $firstNameKana: String,
    $lastName: String,
    $lastNameKana: String,
    $email: String,
    $telNumber: String,
    $isActive: Boolean
  ){
    updateUser(
      data: {
        id: $id,
        firstName: $firstName,
        lastName: $lastName,
        firstNameKana: $firstNameKana,
        lastNameKana: $lastNameKana,
        email: $email,
        isActive: $isActive,
        telNumber: $telNumber
      }
    ) {
      id
      email
      firstName
      firstNameKana
      lastName
      lastNameKana
      telNumber
      isActive
    }
  }
`
