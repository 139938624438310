import { handleGqlResponseBody } from "@/utils/apollo.util"
import { GET_LOGIN_HISTORY_PAGINATION_QUERY } from '@/graphql/queries/user/get-login-history-pagination.query'
import { GET_LOGIN_HISTORY_ALL_QUERY } from '@/graphql/queries/user/get-login-history-all.query'

const loginHistory = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getLoginHistoryQuery ({ commit }, {
      skip, take, orderBy, search, excludeSearch, loginDate, loginTime, logoutDate, logoutTime
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_LOGIN_HISTORY_PAGINATION_QUERY,
          variables: {
            skip, take, orderBy, search, excludeSearch, loginDate, loginTime, logoutDate, logoutTime
          },
          fetchPolicy: "no-cache"
        })
      })
    },
    async getLoginHistoryAllQuery ({ commit }, {
      search, excludeSearch, loginDate, loginTime, logoutDate, logoutTime
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_LOGIN_HISTORY_ALL_QUERY,
          variables: {
            search, excludeSearch, loginDate, loginTime, logoutDate, logoutTime
          },
          fetchPolicy: "no-cache"
        })
      })
    }
  }
}

export default loginHistory
