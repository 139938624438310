import gql from 'graphql-tag'

export const GET_EVSTATION_QUERY = gql`
  query geEvStation (
    $id: Int!
  ) {
    getEvStation(
      id: $id
    ){
      id
      name
      remarks
      imageUri
      apiKey
      dayChargeFee
      nightChargeFee
      nightStartsFrom
      dayStartsFrom
      isEnabled
      propertyId
    }
  }
`

export const GET_PROPERTY_EVSTATION_PAGINATION_QUERY = gql`
  query getPropertyEvStations (
    $propertyId: Int
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getPropertyEvStations(
      data: {
        propertyId: $propertyId
        pagination: {
          skip: $skip
          take: $take
        }
        orderBy: $orderBy
      }
    ){
      hasMore
      total
      items {
        id
        name
        remarks
        imageUri
        dayChargeFee
        nightChargeFee
        nightStartsFrom
        dayStartsFrom
        isEnabled
      }
    }
  }
`

export const GET_VSTATION_CHARGES_PAGINATION_QUERY = gql`
query getEvStationCharges(
  $data: GetEvStationChargesInput!,
  $propertyId: Int!
) {
  getEvStationCharges(
    data: $data
    propertyId: $propertyId
  ) {
    hasMore
    total
    items {
      id
      evStation { name }
      familyMember { fullName propertyRoom { number } }
      chargeStartsAt
      chargeEndsAt
      chargeFee
      paymentError
    }
  }
}
`

export const UPLOAD_EVSTATION_PHOTO_MUTATION = gql`
mutation uploadEvStationImage ($data: UploadEvStationImageInput!) {
  uploadEvStationImage(data: $data) {
    url
  }
}
`

export const CREATE_EVSTATION_MUTATION = gql`
mutation createEvStation ($data: CreateEvStationInput!) {
  createEvStation(data: $data) {
    id
  }
}
`

export const UPDATE_EVSTATION_MUTATION = gql`
mutation createEvStation ($data: UpdateEvStationInput!) {
  updateEvStation(data: $data) {
    id
  }
}
`

export const DELETE_EVSTATION_MUTATION = gql`
mutation deleteEvStation($id: Int!) {
  deleteEvStation(id: $id)
}
`

export const GET_EVSTATION_TERMS_QUERY = gql`
  query GetPropertyForTermsQuery ( $id: Int! ) {
    getProperty(data: { id: $id }) {
      id
      evStationTerms
    }
  }
`

export const SET_EVSTATION_TERMS_MUTATION = gql`
mutation setPropertyEvStationTerms($propertyId: Int!, $terms: String!) {
  setPropertyEvStationTerms( data: {
    propertyId: $propertyId
    terms: $terms
  }) {
    terms
  }
}
`
