import { handleGqlResponseBody } from "@/utils/apollo.util"
import { GET_PROPERTY_FLOORS_QUERY } from '@/graphql/queries/property/get-property-floors.query'

const propertyFloor = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getPropertyFloorsQuery ({ commit }, { propertyId }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTY_FLOORS_QUERY,
          variables: { propertyId },
          fetchPolicy: "no-cache"
        })
      })
    }
  }
}

export default propertyFloor
