import gql from 'graphql-tag'

export const GET_PROPERTY_INTERCOM_PAGINATION_QUERY = gql`
  query GetPropertyIntercomPagination (
    $propertyId: Int
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
  ) {
    getPropertyIntercoms(
      data: {
        propertyId: $propertyId
        pagination: {
          skip: $skip
          take: $take
        }
        orderBy: $orderBy
      }
    ){
      hasMore
      total
      items {
        id
        name
        property {
          id
          name
        }
        isEnabled
        token
        logoutCode
      }
    }
  }
`
