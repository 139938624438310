import gql from 'graphql-tag'

export const CREATE_PROPERTY_MUTATION = gql`
  mutation CreateProperty (
    $address: String!
    $floorsNumber: Int!
    $indoorTablet: Boolean!
    $name: String!
    $smartIntercom: Boolean!
    $streetAddress: String!
    $zipCode: String!
  ){
    createProperty(
      data: {
        address: $address,
        floorsNumber: $floorsNumber,
        indoorTablet: $indoorTablet,
        name: $name,
        smartIntercom: $smartIntercom,
        streetAddress: $streetAddress,
        zipCode: $zipCode
      }
    ) {
      id
      address
      floorsNumber
      indoorTablet
      name
      smartIntercom
      streetAddress
      zipCode
    }
  }
`
