import gql from 'graphql-tag'

export const GET_PROPERTY_QUERY = gql`
  query GetPropertyQuery ( $id: Int! ) {
    getProperty(data: { id: $id }) {
      id
      address
      floorsNumber
      indoorTablet
      name
      smartIntercom
      streetAddress
      zipCode
    }
  }
`
