import gql from 'graphql-tag'

export const UPDATE_INTERCOM_MUTATION = gql`
  mutation UpdateIntercom (
    $id: Int!,
    $logoutCode: String!
    $propertyId: Int!
    $isEnabled: Boolean!
    $name: String!
    $token: String!
  ){
    updateIntercom(
      data: {
        id: $id,
        isEnabled: $isEnabled,
        logoutCode: $logoutCode,
        propertyId: $propertyId,
        name: $name,
        token: $token,
      }
    ) {
      id
      name
      propertyId
      isEnabled
      token
      logoutCode
    }
  }
`
