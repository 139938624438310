import { setContext } from "apollo-link-context"
import { getFromLocalStorage, setToLocalStorage } from "@/utils/local-storage.util"
import { onError } from "apollo-link-error"
import { ApolloClient } from "apollo-client"
import { ApolloLink, Observable } from "apollo-link"
import { InMemoryCache } from "apollo-cache-inmemory"
import VueApollo from "vue-apollo"
import { handleGqlResponseBody } from "@/utils/apollo.util"
import { REFRESH_TOKEN_MUTATION } from "@/graphql/mutations/refresh-token.mutation"
import { clearLoginData } from '@/store/modules/authentication'
import { createUploadLink } from 'apollo-upload-client'

const uri = process.env.VUE_APP_GRAPHQL_HTTP

const uploadLink = createUploadLink({ uri })
// const httpLink = new HttpLink({
//   uri
// })

const authLink = setContext(async (ctx, { headers, ...rest }) => {
  const token = getFromLocalStorage('accessToken')
  if (!token) {
    return { headers }
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : null
    }
  }
})

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.error(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    )
  }
  if (networkError) console.error(`[Network error]: ${networkError}`)

  // User access token has expired
  if (graphQLErrors && graphQLErrors[0].message === 'Unauthorized') {
    // We assume we have both tokens needed to run the async request
    const accessToken = getFromLocalStorage('accessToken')
    const refreshToken = getFromLocalStorage('refreshToken')
    if (refreshToken && accessToken) {
      // Let's refresh token through async request
      return new Observable(observer => {
        handleGqlResponseBody(apollo => {
          return apollo.mutate({
            mutation: REFRESH_TOKEN_MUTATION,
            variables: { refreshToken }
          })
        }).then(({ accessToken }) => {
          setToLocalStorage('accessToken', accessToken)
          operation.setContext(({ headers = {} }) => ({
            headers
          }))
        }).then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer)
          }

          // Retry last failed request
          forward(operation).subscribe(subscriber)
        })
          .catch(error => {
            clearLoginData()
            // No refresh or client token available, we force user to login
            observer.error(error)
          })
      })
    }
  }
})

// create the apollo client
export const apolloClient = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink.concat(uploadLink)]),
  cache: new InMemoryCache()
})

export const apolloProvider = new VueApollo({
  defaultClient: apolloClient
})
