import gql from 'graphql-tag'

export const UPDATE_PROPERTY_MUTATION = gql`
  mutation UpdateProperty (
    $id: Int!,
    $address: String!
    $floorsNumber: Int!
    $indoorTablet: Boolean!
    $name: String!
    $smartIntercom: Boolean!
    $streetAddress: String!
    $zipCode: String!
  ){
    updateProperty(
      data: {
        id: $id,
        address: $address,
        floorsNumber: $floorsNumber,
        indoorTablet: $indoorTablet,
        name: $name,
        smartIntercom: $smartIntercom,
        streetAddress: $streetAddress,
        zipCode: $zipCode
      }
    ) {
      id
      address
      floorsNumber
      indoorTablet
      name
      smartIntercom
      streetAddress
      zipCode
    }
  }
`
