import gql from 'graphql-tag'

export const GET_PROPERTY_FLOORS_WITH_ROOMS_QUERY = gql`
  query GetPropertyFloorsWithRoomsQuery ( $propertyId: Int! ) {
    getPropertyFloorsWithRooms(data: {
      propertyId: $propertyId,
      orderBy: [{
        direction: Desc, fieldName: "serialNumber"
      }, {
        direction: Asc, fieldName: "rooms.serialNumber"
      }]
    }) {
      id
      propertyId
      number
      serialNumber
      rooms {
        id
        number
        serialNumber
      }
    }
  }
`

export const GET_PROPERTY_FLOORS_WITH_ROOMS_AND_USERS_QUERY = gql`
  query GetPropertyFloorsWithRoomsAndUsersQuery ( $propertyId: Int! ) {
    getPropertyFloorsWithRooms(data: {
      propertyId: $propertyId,
      orderBy: [{
        direction: Desc, fieldName: "serialNumber"
      }, {
        direction: Asc, fieldName: "rooms.serialNumber"
      }]
    }) {
      id
      propertyId
      number
      serialNumber
      rooms {
        id
        number
        serialNumber
        status
        familyMember {
          fullName
          representativeAccount {
            id
            firstNameKana
            lastNameKana
            email
            telNumber
          }
        }
      }
    }
  }
`
