import Vue from "vue"

export default {
  state: {
    confirm: null
  },
  getters: {
    isConfirmOpen: (state) => !!state.confirm
  },
  mutations: {
    openConfirm (state, payload) {
      state.confirm = payload
    },
    closeConfirm (state) {
      state.confirm = null
    },
    setConfirmLoading (state, loading) {
      Vue.set(state, 'loading', loading)
    }
  },
  actions: {
    openConfirm ({ commit }, payload) {
      commit('openConfirm', payload)
    },
    async clickConfirm ({ state, commit }) {
      if (state.confirm?.onConfirm) {
        commit('setConfirmLoading', true)
        await state.confirm.onConfirm(state.data)
        commit('setConfirmLoading', false)
      }
    }
  }
}
