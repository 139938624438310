import gql from 'graphql-tag'

export const GET_PROPERTY_FLOORS_QUERY = gql`
  query GetPropertyFloors ($propertyId: Int!) {
    getPropertyFloors(
      data: {
        propertyId: $propertyId
        orderBy: [
          {
            fieldName: "serialNumber"
            direction: Asc
          }
        ]
      }
    ){
      id
      number
      serialNumber
    }
  }
`
