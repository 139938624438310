<template>
  <div>
    <v-navigation-drawer
      width="260"
      mini-variant-width="65"
      v-model="drawer"
      :permanent="navigationDrawerState"
      :expand-on-hover="isExpanded"
      dark
      fixed
    >
      <template v-slot:prepend>
        <v-list-item class="logo-item">
          <v-list-item-avatar class="logo-min">
            <div  v-if="$isDaikoku"></div>
            <v-img v-else src="@/assets/images/logo.svg"></v-img>
          </v-list-item-avatar>
          <v-list-item-title class="logo-title">{{ $appName }}</v-list-item-title>
        </v-list-item>
      </template>

      <v-list
        nav
        dense
      >
        <v-list-item
          v-for="menuItem in mainMenuItems"
          :key="menuItem.title"
          link
          :to="menuItem.route"
          class="sidebar-list-item"
        >
          <v-list-item-icon>
            <v-img v-if="menuItem.iconSvg" :src="menuItem.iconSvg" contain></v-img>
          </v-list-item-icon>
          <v-list-item-title class="menu-title">{{ menuItem.title }}</v-list-item-title>
        </v-list-item>
      </v-list>

      <template v-slot:append>
        <v-list
          nav
          dense
        >
          <v-list-item
            class="sidebar-account"
          >
            <v-list-item-icon class="account-icon">
              <v-icon>mdi-account-circle</v-icon>
              <div class="account-name">{{ currentUser.lastName }} {{ currentUser.firstName }}</div><!--name-lastname-->
            </v-list-item-icon>
            <v-list-item-title v-if="currentUser" class="account-info">
              <div class="account-id">{{ userId }}</div>
              <div class="account-name">{{ currentUser.lastName }} {{ currentUser.firstName }}</div>
              <div @click="logout" class="account-logout">ログアウト</div>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>
    <v-toolbar color="white" class="hidden-sm-and-up" width="100%">
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
      ></v-app-bar-nav-icon>
    </v-toolbar>
  </div>
</template>

<script>

import { toZPad } from "@/utils/string"

export default {
  name: 'SidebarLayout',
  data () {
    return {
      drawer: false,
      isExpanded: false,
      mainMenuItems: [
        {
          iconSvg: require('../assets/images/building.svg'),
          title: '物件管理',
          route: '/facilities'
        },
        {
          iconSvg: require('../assets/images/setting.svg'),
          title: 'ユーザー管理',
          route: '/users'
        },
        {
          iconSvg: require('../assets/images/login-history.svg'),
          title: 'ログイン履歴',
          route: '/login-history'
        }
      ]
    }
  },
  emits: {
    'is-sidebar-open': null
  },
  mounted () {
    this.isExpanded = this.navigationDrawerState
  },
  watch: {
    navigationDrawerState (state) {
      this.drawer = state
      this.isExpanded = state
    },
    isExpanded (value) {
      this.$emit('is-sidebar-open', value)
    }
  },
  computed: {
    navigationDrawerState () {
      return ['sm', 'md', 'lg', 'xl'].includes(this.$vuetify.breakpoint.name)
    },
    currentUser () {
      return this.$store.getters.getCurrentUser
    },
    userId () {
      return toZPad(this.currentUser.id, 6)
    }
  },
  methods: {
    async logout () {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('logoutUser')
        await this.$router.push({ path: '/login' })
      })
    },
    async getMe () {
      await this.$showGqlError(async () => {
        await this.$store.dispatch('getCurrentUser')
      })
    }
  }
}
</script>
