import gql from 'graphql-tag'

export const GET_INTERCOM_QUERY = gql`
  query GetIntercomQuery ( $id: Int!, $propertyId: Int! ) {
    getIntercom(id: $id, propertyId: $propertyId) {
      id
      name
      property {
        id
        name
      }
      isEnabled
      token
      logoutCode
    }
  }
`
