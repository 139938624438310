import gql from 'graphql-tag'

export const GET_LOGIN_HISTORY_ALL_QUERY = gql`
  query GetLoginHistoryAll (
    $search: String
    $excludeSearch: Boolean
    $loginDate: DateString
    $loginTime: TimeString
    $logoutDate: DateString
    $logoutTime: TimeString
  ) {
    getAllLoginHistories(
      data: {
        search: $search,
        excludeSearch: $excludeSearch,
        loginDate: $loginDate,
        loginTime: $loginTime,
        logoutDate: $logoutDate,
        logoutTime: $logoutTime
      }
    ){
      id
      fmuId
      fmuFullName
      ipAddress
      loginAt
      logoutAt
    }
  }
`
