import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import ja from 'vuetify/lib/locale/ja'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#EB9000',
        secondary: '#25A1C6',
        strongSecondary: '#00496F',
        tertiary: '#707070'
      }
    }
  },
  icons: {
    iconfont: 'mdiSvg' // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    locales: { ja },
    current: 'ja'
  }
})
