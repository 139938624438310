import { CREATE_EVSTATION_MUTATION, DELETE_EVSTATION_MUTATION, GET_EVSTATION_QUERY, GET_PROPERTY_EVSTATION_PAGINATION_QUERY, GET_VSTATION_CHARGES_PAGINATION_QUERY, SET_EVSTATION_TERMS_MUTATION, UPDATE_EVSTATION_MUTATION, UPLOAD_EVSTATION_PHOTO_MUTATION } from "@/graphql/ev-station/ev-station-graphql"
import { handleGqlResponseBody } from "@/utils/apollo.util"

async function uploadImage (apollo, file, id) {
  const result = await apollo.mutate({
    mutation: UPLOAD_EVSTATION_PHOTO_MUTATION,
    variables: {
      data: {
        file,
        id
      }
    }
  })
  return result.data.uploadEvStationImage
}

const evStation = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getEvStationQuery (_ctx, id) {
      return handleGqlResponseBody(async apollo => {
        return apollo.query({
          query: GET_EVSTATION_QUERY,
          variables: { id: Number(id) },
          fetchPolicy: 'no-cache'
        })
      })
    },
    getEvStationChargesQuery (_ctx, {
      skip, take, orderBy, propertyId, data
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_VSTATION_CHARGES_PAGINATION_QUERY,
          variables: {
            skip, take, orderBy, propertyId, data
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async getPropertyEvStationsQuery (_ctx, {
      skip, take, orderBy, propertyId
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTY_EVSTATION_PAGINATION_QUERY,
          variables: {
            skip, take, orderBy, propertyId
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async createEvStation (_ctx, {
      id, // not needed
      imageUri,
      ...rest
    }) {
      return handleGqlResponseBody(async apollo => {
        if (imageUri instanceof File) {
          // must upload the file first!
          const result = await uploadImage(apollo, imageUri)
          imageUri = result.url
        }
        return apollo.mutate({
          mutation: CREATE_EVSTATION_MUTATION,
          variables: {
            data: {
              ...rest,
              imageUri,
              dayChargeFee: Number(rest.dayChargeFee),
              nightChargeFee: Number(rest.nightChargeFee)
            }
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async updateEvStation (_ctx, {
      imageUri,
      ...rest
    }) {
      return handleGqlResponseBody(async apollo => {
        if (imageUri instanceof File) {
          // must upload the file first!
          const result = await uploadImage(apollo, imageUri)
          imageUri = result.url
        }
        return apollo.mutate({
          mutation: UPDATE_EVSTATION_MUTATION,
          variables: {
            data: {
              ...rest,
              imageUri,
              dayChargeFee: Number(rest.dayChargeFee),
              nightChargeFee: Number(rest.nightChargeFee)
            }
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async deleteEvStation ({ commit }, { id }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: DELETE_EVSTATION_MUTATION,
          variables: { id },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async setPropertyEvStaionTerms (_ctx, { propertyId, terms }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: SET_EVSTATION_TERMS_MUTATION,
          variables: { propertyId, terms },
          fetchPolicy: 'no-cache'
        })
      })
    }
  }
}

export default evStation
