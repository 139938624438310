<template>
  <main-layout>
    <div class="main-container">
      <router-view />
    </div>
  </main-layout>
</template>

<script>
import MainLayout from '../layouts/MainLayout'

export default {
  components: { MainLayout }
}
</script>
