import gql from 'graphql-tag'

export const LOGIN_MUTATION = gql`
  mutation LoginMutation($email: String!, $password: String!) {
    loginUser(
      data: {
        email: $email
        password: $password
      }
    ) {
      accessToken
      refreshToken
      user {
        id
        role
        email
        propertyRoomId
        firstName
        lastName
      }
      loginHistoryId
    }
  }
`
