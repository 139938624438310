import { getFromLocalStorage, setToLocalStorage } from "@/utils/local-storage.util"
import { handleGqlResponseBody } from "@/utils/apollo.util"
import { GET_CURRENT_USER_QUERY } from "@/graphql/queries/user/get-current-user.query"

const currentUser = {
  state: {
    user: getFromLocalStorage('user') ? JSON.parse(getFromLocalStorage('user')) : null
  },
  getters: {
    getCurrentUser (state) {
      return state.user
    }
  },
  mutations: {
    setCurrentUser (state, user) {
      setToLocalStorage('user', JSON.stringify(user))
      state.user = user
    }
  },
  actions: {
    async getCurrentUser ({ commit }) {
      const result = await handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: GET_CURRENT_USER_QUERY
        })
      })

      commit('setCurrentUser', result)
      return result
    }
  }
}

export default currentUser
