import gql from 'graphql-tag'

export const RESET_FAMILY_MEMBER_PASSWORD_MUTATION = gql`
  mutation ResetFamilyMemberPassword ($username: String!){
    resetFamilyMemberPassword(
      data: {
        username: $username
      }
    )
  }
`
