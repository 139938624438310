import { handleGqlResponseBody } from "@/utils/apollo.util"
import { GET_INTERCOM_QUERY } from '@/graphql/queries/property/get-intercom.query'
import { GET_PROPERTY_INTERCOM_PAGINATION_QUERY } from '@/graphql/queries/property/get-property-intercoms-pagination.query'
import { CREATE_INTERCOM_MUTATION } from '@/graphql/mutations/intercom/create.mutation'
import { UPDATE_INTERCOM_MUTATION } from '@/graphql/mutations/intercom/update.mutation'
import { DELETE_INTERCOM_MUTATION } from '@/graphql/mutations/intercom/delete.mutation'

const intercom = {
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async getIntercomQuery ({ commit }, { id, propertyId }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_INTERCOM_QUERY,
          variables: { id, propertyId },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async getPropertyIntercomsQuery ({ commit }, {
      skip, take, orderBy, propertyId
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.query({
          query: GET_PROPERTY_INTERCOM_PAGINATION_QUERY,
          variables: {
            skip, take, orderBy, propertyId
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async createIntercom ({ commit }, {
      logoutCode, propertyId, isEnabled, name, token
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: CREATE_INTERCOM_MUTATION,
          variables: {
            logoutCode, propertyId, isEnabled, name, token
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async updateIntercom ({ commit }, {
      id, logoutCode, propertyId, isEnabled, name, token
    }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: UPDATE_INTERCOM_MUTATION,
          variables: {
            id, logoutCode, propertyId, isEnabled, name, token
          },
          fetchPolicy: 'no-cache'
        })
      })
    },
    async deleteIntercom ({ commit }, { id, propertyId }) {
      return handleGqlResponseBody(apollo => {
        return apollo.mutate({
          mutation: DELETE_INTERCOM_MUTATION,
          variables: { id, propertyId },
          fetchPolicy: 'no-cache'
        })
      })
    }
  }
}

export default intercom
