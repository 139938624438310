import gql from 'graphql-tag'

export const CONFIRM_RESET_FAMILY_MEMBER_PASSWORD_MUTATION = gql`
  mutation ConfirmResetFamilyMemberPassword ($token: String!, $code: String!, $password: String!){
    confirmResetFamilyMemberPassword(
      data: {
        token: $token
        code: $code
        password: $password
      }
    )
  }
`
