<template>
  <div>
    ページが見つかりません
  </div>
</template>

<script>

export default {
  name: 'PageNotFound'
}
</script>

<style scoped>

</style>
