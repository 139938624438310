import gql from 'graphql-tag'

export const GET_PROPERTIES_PAGINATION_QUERY = gql`
  query GetPropertiesPagination (
    $name: String
    $skip: Int!
    $take: Int!
    $orderBy: [OrderByInput!]
    $ownerIds: [Int!]
  ) {
    getProperties(
      data: {
        name: $name
        ownerIds: $ownerIds
        pagination: {
          skip: $skip
          take: $take
        }
        orderBy: $orderBy
      }
    ){
      hasMore
      total
      items {
        id
        name
        address
        zipCode
        address
        streetAddress
        floorsNumber
        indoorTablet
        smartIntercom
      }
    }
  }
`
