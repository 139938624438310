import gql from 'graphql-tag'

export const CREATE_INTERCOM_MUTATION = gql`
  mutation CreateIntercom (
    $propertyId: Int!
    $isEnabled: Boolean!
    $name: String!
    $token: String!
    $logoutCode: String!
  ){
    createIntercom(
      data: {
        name: $name,
        propertyId: $propertyId,
        isEnabled: $isEnabled,
        token: $token,
        logoutCode: $logoutCode
      }
    ) {
      id
      name
      propertyId
      isEnabled
      token
      logoutCode
    }
  }
`
