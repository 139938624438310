import gql from 'graphql-tag'

export const RESEND_USERS_PASSWORD_MUTATION = gql`
  mutation ResentUsersPassword ($usersId: [Int!]!) {
    resetUsersPassword(
      data: {
        usersId: $usersId
      }
    ){
      succeed
      failed
    }
  }
`
