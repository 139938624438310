export const USER_ROLE =
  {
    ADMIN: 'ADMIN',
    PROPERTY_OWNER: 'PROPERTY_OWNER',
    REPRESENTATIVE_RESIDENT: 'REPRESENTATIVE_RESIDENT'
  }

export const USER_ROLE_TRANSLATE =
  {
    ADMIN: '管理者',
    PROPERTY_OWNER: '一般ユーザー',
    REPRESENTATIVE_RESIDENT: '代表的な居住者'
  }
